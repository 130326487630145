<script>
  const tileBands = document.querySelectorAll('.cmp-tile-band');
  tileBands.forEach(tileBand => {
    const bgURL = tileBand.getAttribute('data-bg');
    if(bgURL && bgURL!=null){
      tileBand.style.backgroundImage = `url(${bgURL})`;
      tileBand.classList.add('cmp-tile-band--has-bg-image');
    }
    const tiles = tileBand.querySelectorAll('.cmp-tile-band__tiles');
    const tilesCount = tiles.length;
    if(tilesCount === 4) {
      tileBand.querySelector('.cmp-tile-band__container').classList.add('cmp-tile-band--four-tiles');
    }

    tiles.forEach(tile => {
      const button = tile.querySelector('a');
      const title = tile.querySelector('.cmp-tile-band__tiles--title')?.innerText;
      button.setAttribute("parentId", tileBand.id);
      if(!button.ariaLabel){
        if(title){
          button.ariaLabel = button.text.trim() + ": " + title
        }
        else{
          button.ariaLabel = button.text.trim()
        }
      }
    });

  });
    
import CoreComponent from '../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/CoreComponent.vue'

export default {
  extends: CoreComponent
}
</script>

<style lang="scss">
@import './tile-band.scss';
</style>
